<template>
  <div id="filter-list">
    <h3>Filter List</h3>
    <FilterBar class="p-2" />

    <Vuetable
      data-path="mydata"
      ref="vuetable"
      @vuetable:pagination-data="onPaginationData"
      @vuetable:loading="showLoader"
      @vuetable:loaded="hideLoader"
      pagination-path="pagination"
      :api-url="apiUrl"
      :fields="fields"
      :http-options="httpOptions"
      :css="css.table"
      :per-page="4"
      :append-params="moreParams"
      :query-params="{
        sort: 'sort',
        page: 'page',
        perPage: 'perPage'
      }">

      <!-- Icon -->
      <template slot="iconUrl" slot-scope="props">
        <!-- <img
          v-if="props.rowData.iconUrl !==''"
          :src="props.rowData.iconUrl"
          alt="thumbnail"
          width="120"
          height="120"
          @click="itemAction('change-thumbnail',
          props.rowData, props.rowIndex)"
        /> -->
        <img
          v-if="props.rowData.iconUrl !==''"
          :src="props.rowData.iconUrl"
          alt="thumbnail"
          width="120"
          height="120"
        />
        <span v-else>Not Available</span>
      </template>

      <!-- Update Order -->
      <template class="text-center" slot="order" slot-scope="props">
        <select
          class="form-control"
          v-model="props.rowData.orderNo"
          @change="changeOrder(props.rowData)">
          <option
            v-for="(value,key) in orderOptions"
            :key="key"
            :value="value">
            {{ value }}
          </option>
        </select>
      </template>

      <!-- Actions -->
      <template class="text-center" slot="actions" slot-scope="props">
        <FilterListActions
          :rowData="props.rowData"
          :rowIndex="props.rowIndex"
        />
      </template>

      <!-- <template class="text-center" slot="fileUrl" slot-scope="props">
        <code>{{ props.rowData.fileUrl }}</code>
        <b-input-group size="sm">
          <b-form-input
            class="form-control"
            :id="`input-${props.rowIndex}`"
            type="url"
            :value="props.rowData.fileUrl"
            readonly
          />
          <b-input-group-append>
            <b-button @click="copyToClipboard(props.rowIndex)">
              <b-icon icon="files"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </template> -->
    </Vuetable>
    <div class="d-flex align-items-center justify-content-end">
      <VuetablePaginationInfo ref="paginationInfo" />
      <VuetablePagination
        ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
        :css="css.pagination"
      />
    </div>

    <!-- Modal -->
    <v-dialog/>
    <modals-container />

    <!-- Loading -->
    <AppLoaderAdmin :isLoading="loading" />
  </div>
</template>

<script>
  import Vue from 'vue';
  import TableStyle from '../table-bootstrap-css.js';

  export default {
    name : 'FilterList',
    data() {
      return {
        loading     : false,
        maxOrder    : 0,
        apiUrl      : process.env.VUE_APP_ROOT_API + 'api/filters/admin',
        moreParams  : {},
        css         : TableStyle,
        httpOptions : {
          headers : {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`,
          },
        },
        fields : [
          {
            name       : 'mobileFilterId',
            title      : 'Filter<br>ID#',
            sortField  : 'mobile_filter_assets_id',
            direction  : 'desc',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name      : 'name',
            title     : 'Filter Name',
            sortField : 'name',
            direction : 'desc',
          },
          {
            name       : '__slot:iconUrl',
            title      : 'Icon',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name       : 'triggerType',
            title      : 'Trigger',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name       : '__slot:order',
            title      : 'Order',
            sortField  : 'order_no',
            direction  : 'desc',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name       : 'published',
            title      : 'Published',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name       : 'class',
            title      : 'Class',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name       : '__slot:actions',
            title      : 'Actions',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },

          // {
          //   name       : '__slot:fileUrl', //'fileUrl',
          //   title      : 'File Url',
          //   titleClass : 'text-center',
          //   dataClass  : 'text-center',
          // },
        ],
      };
    },
    computed : {
      orderOptions() {
        const options = [];
        for(let i = 1; i <= this.maxOrder; i++)
          options.push(i);
        
        return options;
      },
    },
    components : {
      'AppLoaderAdmin'     : () => import('@/components/layout/AppLoaderAdmin'),
      'Vuetable'           : () => import('vuetable-2/src/components/Vuetable'),
      'VuetablePagination' :
        () => import('vuetable-2/src/components/VuetablePagination'),
      'VuetablePaginationInfo' :
        () => import('vuetable-2/src/components/VuetablePaginationInfo'),
      'FilterBar'         : () => import('./FilterBar.vue'),
      'FilterListActions' : () => import('./FilterListActions'),
    },
    methods : {

      /**
       * Get Transformed data
       * @param data
       */
      transform : function (data) {
        
        data = data.result;
        const transformed = {};

        transformed.pagination = {
          'total'         : data.total,
          'per_page'      : data.perPage,
          'current_page'  : data.currentPage,
          'last_page'     : data.lastPage,
          'next_page_url' : data.nextPageUrl,
          'prev_page_url' : data.prevPageUrl,
          'from'          : data.from,
          'to'            : data.to,
        };

        transformed.mydata = [];
        
        for (let i = 0; i < data.data.length; i++) {
          transformed.mydata.push({
            mobileFilterId : data.data[i].mobile_filter_assets_id,
            name           : data.data[i].name,
            fileUrl        : data.data[i].file_url,
            iconUrl        : data.data[i].icon_url,
            published      : data.data[i].published,
            triggerType    : data.data[i].trigger_name,
            triggerId      : data.data[i].trigger_type,
            created        : data.data[i].created,
            orderNo        : data.data[i].order_no,
            class          : data.data[i].class ? 'Filter/Effects' : 'Mask',
            subtype        : data.data[i].subscription_type,
          });
          if (data.data[i].order_no > this.maxOrder)
            this.maxOrder = data.data[i].order_no;
        }
        return transformed;
      },

      /**
       * Change page
       * @param page
       */
      onChangePage(page) {
        this.$refs.vuetable.changePage(page);
      },
      
      /**
       * Set Pagination Info
       * @param paginationData
       */
      onPaginationData(paginationData) {
        this.$refs.pagination.setPaginationData(paginationData);
        this.$refs.paginationInfo.setPaginationData(paginationData);
      },

      /**
       * Set Filter
       */
      onFilterSet(filterText) {
        this.moreParams = {
          'filter' : filterText,
        };
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
       * Reset Filter
       */
      onFilterReset() {
        this.moreParams = {};
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
       * Change filter order
       * @param data
       */
      changeOrder(data) {
        this.showLoader();
        this.$http.post('api/setFilterOrderNumber', {
          mFilterId : data.mobileFilterId,
          orderNo   : data.orderNo,
        }).then(() => {
          this.$refs.vuetable.refresh();
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Updated order successfully',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops Something Went Wrong!. Please try again later.',
          });
        }).finally(() => {
          this.hideLoader();
        });
      },

      showLoader() {
        this.loading = true;
      },
      hideLoader() {
        this.loading = false;
      },

      // copyToClipboard(index) {
      //   const elId = `input-${index}`;
      //   const elem = document.getElementById(elId);

      //   elem.select();
      //   elem.setSelectionRange(0, 99999);
      //   document.execCommand("copy");

      //   this.$notify({
      //     group : 'notif',
      //     type  : 'success',
      //     text  : 'Copied url',
      //   });
      // },
    },
    mounted() {
      this.$events.$on('filter-set-filter', eventData =>
        this.onFilterSet(eventData));
      this.$events.$on('filter-reset-filter', () => this.onFilterReset());
      this.$events.$on('reload', () =>
        Vue.nextTick(() => this.$refs.vuetable.refresh()));
      this.$events.$on('loading-state', eventData => {
        if (eventData)
          this.showLoader();
        else
          this.hideLoader();
      });
    },
  
  }
</script>
<style>
</style>